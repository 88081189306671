import store from "../store/store";
import router from "../router/router";
import { apiAgent } from "../services/apiAgent";

export default {
  async post(url, data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await apiAgent.post(url, data);
          resolve(res);
        } catch (err) {
          let error = await this.validateRequest(err);
          reject(error);
        }
      })();
    });
  },

  async get(url) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await apiAgent(url);
          resolve(res);
        } catch (err) {
          let error = await this.validateRequest(err);
          reject(error);
        }
      })();
    });
  },

  async update(url, data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await apiAgent({ method: "patch", url, data });
          resolve(res);
        } catch (err) {
          let error = await this.validateRequest(err);
          reject(error);
        }
      })();
    });
  },

  async updateOne(url, data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await apiAgent({ method: "patch", url, data });
          resolve(res);
        } catch (err) {
          let error = await this.validateRequest(err);
          reject(error);
        }
      })();
    });
  },

  async updateMany(url, data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await apiAgent({ method: "patch", url, data });
          resolve(res);
        } catch (err) {
          let error = await this.validateRequest(err);
          reject(error);
        }
      })();
    });
  },

  async delete(url, data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await apiAgent({
            method: "delete",
            url,
            data,
          });
          resolve(res);
        } catch (err) {
          let error = await this.validateRequest(err);
          reject(error);
        }
      })();
    });
  },

  async validateRequest(err) {
    // Message
    const errorMessage = err.response?.data?.message || "";
    const status = err?.response?.status || 500;

    // Too many requests
    if (status === 429) {
      return "Liikaa pyyntöjä. Yritä uudelleen 10 sekunnin kuluttua.";
    }

    // Other issue
    if (
      status === 503 &&
      errorMessage.includes("Sivustoon kirjautuminen on väliaikaisesti estetty")
    ) {
      router.push("/login").catch(() => {});
      await store.dispatch("deleteToken");
      return errorMessage;
    }

    if (errorMessage) {
      if (
        errorMessage.startsWith("Authentication failed") ||
        errorMessage.startsWith("jwt expired") ||
        err?.response?.data?.error?.name == "TokenExpiredError" ||
        err?.response?.data?.error?.name == "JsonWebTokenError" ||
        err?.response?.data?.error?.name == "NotBeforeError"
      ) {
        router.push("/login").catch(() => {});
        await store.dispatch("deleteToken");
        return "Käyttäjän tunnistaminen ei onnistunut, kirjaudu uudelleen sisään.";
      }

      if (errorMessage.startsWith("Ohjelmiston käyttö estetty")) {
        router.push("/login").catch(() => {});
        await store.dispatch("deleteToken");
        return "Ohjelmiston käyttö estetty. Ota yhteys ylläpitoon.";
      }

      if (errorMessage.startsWith("ROLE -" || status === 401)) {
        return "Et ole oikeutettu kyseiseen toimenpiteeseen.";
      }

      if (errorMessage.includes("email_1 dup key")) return "Email on jo käytössä";

      return errorMessage;
    }

    return err;
  },
};
