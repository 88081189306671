import isEmail from "validator/lib/isEmail";

export default {
  required: [(v) => !!v || "Pakollinen kenttä"],
  positiveNumber: [(v) => checkPositiveNumber(v) || "Anna positiivinen luku"],
  negativeNumber: [(v) => checkNegativeNumber(v) || "Anna negatiivinen luku"],
  emptyOrPositiveNumber: [
    (v) => checkEmptyOrPositiveNumber(v) || "Anna positiivinen luku tai jätä tyhjäksi",
  ],
  zeroOrGreater: [(v) => checkZeroOrGreater(v) || "Anna luku, joka on 0 tai suurempi"],
  requiredArray: [(v) => checkArrayLength(v) || "Valitse vähintään yksi"],
  requiredBooleanVal: [(v) => checkRequiredBoolean(v) || "Valitse jokin arvo"],
  anyNumber: [(v) => validateNumber(v) || "Valitse jokin arvo"],
  numberBetween: validateNumberBetween,
  email: [(v) => isEmail(String(v)) || "Email ei ole oikeassa muodossa"],
  email2: [(v) => testEmail(v)],
  responsive: [(v) => validateResponsivenes(v)],
  colPadding: [(v) => validateColPadding(v)],

  isValidEmail(v) {
    if (!v) {
      return false;
    }
    return isEmail(String(v));
  },

  isValidReplyToEmail(v) {
    if (v === null) {
      return true;
    }
    return isEmail(String(v));
  },

  maxNumber(baseNum) {
    return (num) => {
      return num <= baseNum || "Liian suuri arvo";
    };
  },

  arrayMaxLength(num) {
    return (arr) => {
      return arr.length <= num || "Liian monta arvoa valittu";
    };
  },
};

function validateNumber(v) {
  if (v === null || v === "") {
    return false;
  }
  const numberValue = parseFloat(v);
  if (isNaN(numberValue)) {
    return false;
  }
  return true;
}

function validateNumberBetween(obj) {
  const max = obj.max;
  const min = obj.min;

  return (v) => {
    if (v === null || v === "") return "Valitse numero";

    const numberValue = parseFloat(v);
    if (isNaN(numberValue)) return "Valitse numero";

    if (v >= min && v <= max) return true;
    else return `Numero ei ole halutulta väliltä ${min}-${max}`;
  };
}

function checkPositiveNumber(v) {
  if (v > 0 || v == "") return true;
  else return false;
}

function checkNegativeNumber(v) {
  if (v < 0 || v == "") return true;
  else return false;
}

function checkZeroOrGreater(v) {
  if (v < 0 || v === "" || v === null) return false;
  else return true;
}

function checkEmptyOrPositiveNumber(val) {
  if (!val) {
    return true;
  }
  return val > 0 || "Anna positiviinen luku";
}

function testEmail(v) {
  if (!v) {
    return true;
  }
  return isEmail(String(v)) || "Email ei ole oikeassa muodossa";
}

function validateResponsivenes(value) {
  const pattern = /^(\d+\s){0,4}\d+$/;
  const numbers = value.split(" ").map(Number);
  if (!pattern.test(value)) {
    return false;
  }
  if (numbers.some((num) => num < 1 || num > 12)) {
    return false;
  }
  if (numbers.length < 1 || numbers.length > 5) {
    return false;
  }
  return true;
}

function validateColPadding(value) {
  if (value == "" || !value) return true;

  const pattern = /^(\d+\s){0,3}\d+$/;
  const numbers = value.split(" ").map(Number);
  if (!pattern.test(value)) {
    return false;
  }
  if (numbers.some((num) => num < 0 || num > 16)) {
    return false;
  }
  if (numbers.length > 4) {
    return false;
  }
  return true;
}

function checkArrayLength(value) {
  if (!value) return false;
  if (typeof value == "object" && Array.isArray(value)) {
    return value.length > 0 ? true : false;
  }
}

function checkRequiredBoolean(value) {
  if (typeof value === "boolean") return true;
  else return false;
}
