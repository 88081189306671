import invoiceService from "../../services/invoice.service";
import { showPopup } from "../../utils/helpers";
import _ from "lodash";
import moment from "moment";
import router from "../../router/router";
import rentIncreaseService from "../../services/rentIncrease.service";

const state = {
  overDueInvoices: [],
  invoices: [],
  payments: [],
  activeInvoices: [],
  totalLength: 0,
  invoice: {},
  principalTenant: { tenantId: { personalRefNumInUse: false } },
  currentInvoice: {
    outgoingPayments: { inUse: false },
    fixedPeriodRecurrency: {
      inUse: false,
      active: false,
      startDate: null,
      repeatsLeft: null,
    },
    sendingAddress: {},
    fixedPeriodProducts: [],
    comments: [],
  },
  watchDueDateChange: false,
  rentIncreaseDueDate: "",
  rentIncreaseBillDate: "",
  fixedPeriodRecurrencyCopy: {},
  recurrentInvoice: { tenant: { email: "" }, sendType: "" },
  measurementsNeeded: 0,
  searchByDates: { startDate: null, endDate: null, searchBy: "dueDate" },
  loading: false,
  loadingStatuses: false,
  saving: false,
};

const mutations = {
  setLoading(state, val) {
    state.loading = val;
  },

  setSaving(state, val) {
    state.saving = val;
  },

  setLoadingStatuses(state, val) {
    state.loadingStatuses = val;
  },

  setPrincipalTenant(state, tenant) {
    state.principalTenant = { ...tenant };
  },

  setDueDateWatcher(state, val) {
    state.watchDueDateChange = val;
  },

  addAllInvoices(state, data) {
    state.invoices = [...data.invoices];
    state.totalLength = data.totalLength;
    state.measurementsNeeded = data.measurementsNeeded;
  },

  addAllPayments(state, data) {
    state.payments = [...data.payments];
    state.totalLength = data.totalLength;
  },

  resetSearchByDates(state, searchBy) {
    state.searchByDates.startDate = null;
    state.searchByDates.endDate = null;
    state.searchByDates.searchBy = searchBy;
  },

  setRentIncreaseDates(state, obj) {
    state[obj.field] = obj.val;
  },

  addInvoicesWithoutMeasurementsNeeded(state, data) {
    state.invoices = [...data.invoices];
    state.totalLength = data.totalLength;
  },

  addOverDueInvoices(state, data) {
    state.overDueInvoices = [...data.invoices];
    state.totalLength = data.totalLength;
  },

  addActiveInvoices(state, invoices) {
    state.activeInvoices = [...invoices, ...state.activeInvoices].filter(
      (value, index, self) => index === self.findIndex((t) => String(t._id) == String(value._id))
    );
  },

  setInvoice(state, invoice) {
    state.currentInvoice = { ...invoice };
  },

  replaceInvoice(state, invoice) {
    const index = state.invoices.findIndex((el) => el._id == invoice._id);
    state.invoices.splice(index, 1, { ...invoice });
  },

  replaceRecurrentInvoice(state, invoice) {
    if (invoice.active) {
      state.recurrentInvoice = { ...invoice };

      const index = state.invoices.findIndex((el) => el._id == invoice._id);

      if (index != -1) {
        state.invoices.splice(index, 1, invoice);
      }
    } else {
      state.invoices = state.invoices.filter((el) => el._id != invoice._id);
    }
  },

  // Freeze object before save
  freezeFixedPeriodRecurrency(state) {
    state.fixedPeriodRecurrencyCopy = Object.freeze(
      _.cloneDeep({
        fixedPeriodProducts: state.currentInvoice.fixedPeriodProducts,
        fixedPeriodRecurrency: state.currentInvoice.fixedPeriodRecurrency,
      })
    );
  },

  // return previous fields if not saved
  cancelFixedPeriodFields(state) {
    state.currentInvoice.fixedPeriodRecurrency =
      state.fixedPeriodRecurrencyCopy.fixedPeriodRecurrency;
    state.currentInvoice.fixedPeriodProducts = state.fixedPeriodRecurrencyCopy.fixedPeriodProducts;
  },

  setRecurrentInvoiceToState(state, invoice) {
    state.recurrentInvoice = { ...invoice };
  },

  // Every time invoice values change
  setRecurrentInvoice(state, invoice) {
    // Set updated tenant and sending address. These will not overwrite tenant and sending address in server, they are only on client side
    if (invoice.updatedTenant && invoice.updatedTenant.name) {
      invoice.tenant.name = invoice.updatedTenant.name || "";
      invoice.tenant.email = invoice.updatedTenant.email || "";
      invoice.tenant.email2 = invoice.updatedTenant.email2 || "";
    } else {
      if (!invoice.tenant.email2) invoice.tenant.email2 = "";
    }

    // Set updated sending address to address and and country if not present
    if (invoice.updatedSendingAddress && invoice.updatedSendingAddress.address) {
      invoice.sendingAddress = invoice.updatedSendingAddress;
      if (!invoice.sendingAddress.country) invoice.sendingAddress.country = null;
    } else {
      if (!invoice.sendingAddress.country) invoice.sendingAddress.country = null;
    }

    if (invoice.isUpdated) invoice.products = _.cloneDeep(invoice.updatedProducts);

    if (invoice.nextDueDate) {
      invoice.nextDueDate = new Date(invoice.nextDueDate).toISOString().substring(0, 10);
    }

    // Fixed period recurrency
    if (invoice.fixedPeriodRecurrency.inUse) {
      invoice.fixedPeriodRecurrency.startDate = new Date(invoice.fixedPeriodRecurrency.startDate)
        .toISOString()
        .substring(0, 7);
      invoice.fixedPeriodProducts = [...invoice.fixedPeriodProducts];
    } else {
      invoice.fixedPeriodRecurrency = {
        active: false,
        inUse: false,
        repeatsLeft: null,
        startDate: null,
      };

      if (invoice.isUpdated) {
        invoice.fixedPeriodProducts = _.cloneDeep(invoice.updatedProducts);
      } else {
        invoice.fixedPeriodProducts = _.cloneDeep(invoice.products);
      }
    }

    // Set currentInvoice state
    state.currentInvoice = { ...invoice };

    // freeze fixedperiod fields
    this.commit("invoice/freezeFixedPeriodRecurrency");
  },

  setFormField(state, obj) {
    state.currentInvoice = _.set(state.currentInvoice, obj.field, obj.val);
  },

  addProduct(state, productType) {
    state.currentInvoice[productType].push({
      desc: "",
      count: null,
      itemtype: "kpl",
      amount: null,
      taxpr: 0,
      accountingId: null,
      productId: null,
    });
  },

  setProduct(state, obj) {
    obj.product.productNumber = obj.event.productNumber;
    obj.product.desc = obj.event.desc;
    obj.product.amount = obj.event.amount;
    obj.product.count = obj.event.count;
    obj.product.taxpr = obj.event.taxpr;
    obj.product.itemtype = obj.event.itemtype;
    obj.product.accountingId = obj.event.accountingId;
    obj.product.productId = obj.event._id;
    delete obj.product._id;

    state.currentInvoice[obj.productType].splice(obj.index, 1, obj.product);
  },

  deleteProduct(state, obj) {
    state.currentInvoice[obj.productType].splice(obj.index, 1);
  },

  removeInvoice(state, id) {
    state.invoices = state.invoices.filter((el) => String(el._id) != String(id));
  },
};

const actions = {
  async getAllInvoices({ commit }, data) {
    try {
      const res = await invoiceService.getAllInvoices(data);
      commit("addAllInvoices", {
        invoices: res.data.invoices,
        totalLength: res.data.totalLength,
        measurementsNeeded: res.data.measurementsNeeded,
      });
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getAllPayments({ commit }, data) {
    try {
      const res = await invoiceService.getAllPayments(data);
      commit("addAllPayments", {
        payments: res.data.payments,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getPartnerInvoices({ commit }, data) {
    try {
      const res = await invoiceService.getAllInvoices(data);
      commit("addInvoicesWithoutMeasurementsNeeded", {
        invoices: res.data.invoices,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getTenantInvoices({ commit }, data) {
    try {
      const res = await invoiceService.getAllInvoices(data);
      commit("addInvoicesWithoutMeasurementsNeeded", {
        invoices: res.data.invoices,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getOverdueInvoices({ commit }, url) {
    try {
      const res = await invoiceService.getOverdueInvoices(url);
      commit("addOverDueInvoices", {
        invoices: res.data.invoices,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      throw new Error(err);
    }
  },

  async searchInvoice({ commit }, url) {
    try {
      const res = await invoiceService.searchInvoice(url);
      commit("addActiveInvoices", res.data.invoices);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getOneInvoice({ commit }, data) {
    try {
      const res = await invoiceService.getOneInvoice(data);
      commit("setInvoice", res.data.invoice);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getInvoiceByServiceId(ctx, serviceId) {
    try {
      const res = await invoiceService.getInvoiceByServiceId(serviceId);
      return res.data.invoice;
    } catch (err) {
      throw new Error(err);
    }
  },

  async updateSingleInvoiceStatuses({ commit }, data) {
    try {
      const res = await invoiceService.updateSingleInvoiceStatuses(data);
      if (res.data.invoice) commit("replaceInvoice", res.data.invoice);
    } catch (err) {
      throw new Error(err);
    }
  },

  async getRentIncreaseByInvoiceId({ commit, state }) {
    try {
      const res = await rentIncreaseService.getRentIncreaseByInvoiceId(state.currentInvoice._id);
      commit("setRentIncrease", res.data.rentIncrease);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async sendInvoice({ commit }, data) {
    try {
      const res = await invoiceService.sendInvoice(data);

      if (res.data.message) {
        commit("replaceInvoice", res.data.invoice);
        showPopup(res.data.message, "success");
      } else {
        commit("removeInvoice", res.data.invoice._id);
        showPopup("Laskun lähetys onnistui", "success");
      }
    } catch (err) {
      throw new Error(err);
    }
  },

  async sendRefundInvoice({ commit }, id) {
    try {
      const res = await invoiceService.sendRefundInvoice(id);
      if (res.data.invoice) {
        commit("removeInvoice", res.data.invoice._id);
      } else {
        showPopup("Lasku lähetettiin, mutta laskun tilaa ei saatu tarkastettua.", "error");
        commit("removeInvoice", id);
      }
    } catch (err) {
      throw new Error(err);
    }
  },

  async createRefundInvoiceWithoutSending({ commit }, data) {
    try {
      await invoiceService.createRefundInvoiceWithoutSending(data);
      commit("removeInvoice", data.id);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createRefundInvoice({ commit }, data) {
    try {
      await invoiceService.createRefundInvoice(data);
      commit("setSaving", false);

      setTimeout(() => {
        router.push("/refund-invoices?tab=1");
      }, 800);
      showPopup("Hyvityslasku luotu", " success");
    } catch (err) {
      throw new Error(err);
    }
  },

  async editRefundInvoice({ commit }, data) {
    try {
      await invoiceService.editRefundInvoice(data);
      commit("setSaving", false);

      setTimeout(() => {
        router.push("/refund-invoices");
      }, 700);
      showPopup("Hyvityslaskun tiedot tallennettu", "success");
    } catch (err) {
      throw new Error(err);
    }
  },

  async updateRecurrentInvoice({ commit }, data) {
    try {
      const res = await invoiceService.updateRecurrentInvoice(data);
      commit("replaceRecurrentInvoice", res.data.invoice);
      showPopup("Laskun tiedot tallennettu", "success");
    } catch (err) {
      throw new Error(err);
    }
  },

  async deleteInvoice({ commit }, id) {
    try {
      await invoiceService.deleteInvoice(id);
      commit("removeInvoice", id);
      showPopup("Lasku poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async makePayment({ getters, commit }, payment) {
    try {
      const serviceId = getters.getCurrentInvoiceServiceId;
      if (!serviceId) throw new Error("Service name not found");

      const data = { amount: payment.amount, serviceId, payDate: payment.payDate };

      const res = await invoiceService.makePayment(data);
      if (res.data.updatedInvoice) commit("replaceInvoice", res.data.updatedInvoice);
    } catch (err) {
      throw new Error(err);
    }
  },

  async changeDueDate({ getters, commit }, dueDate) {
    const serviceId = getters.getCurrentInvoiceServiceId;
    if (!serviceId) throw new Error("Service name not found");

    const obj = { serviceId, dueDate };
    const { data } = await invoiceService.changeDueDate(obj);
    commit("replaceInvoice", data.invoice);
  },

  async changeCostCentreToAllInvoices(ctx, data) {
    try {
      const res = await invoiceService.changeCostCentreToAllInvoices(data);

      if (res.data.includes("ERROR")) {
        const error = res.data.split("ERROR: ");
        throw new Error(error[1]);
      }
    } catch (err) {
      throw new Error(err);
    }
  },

  async changeProductAccountingIdToInvoices() {
    try {
      const res = await invoiceService.changeProductAccountingIdToInvoices();

      if (res.data.includes("ERROR")) {
        const error = res.data.split("ERROR: ");
        throw new Error(error[1]);
      }
    } catch (err) {
      throw new Error(err);
    }
  },
};

const getters = {
  getCurrentInvoiceServiceId(state, getters, rootState, rootGetters) {
    const service = rootGetters["account/invoiceService"];
    let serviceId = null;

    if (service === "ropoCapital") serviceId = state.currentInvoice?.ropoCapital?.jobId;
    if (service === "talenom") serviceId = state.currentInvoice?.talenom?.invoiceId;

    return serviceId;
  },

  fixedPeriodStartInFuture(state) {
    if (state.currentInvoice.fixedPeriodRecurrency.active) return false;

    if (state.currentInvoice.fixedPeriodRecurrency.startDate && state.currentInvoice.nextDueDate) {
      const firstDueDate = moment(state.currentInvoice.fixedPeriodRecurrency.startDate).format(
        "YYYY-MM"
      );
      const nextDueDate = moment(state.currentInvoice.nextDueDate).format("YYYY-MM");

      return moment(firstDueDate).isAfter(nextDueDate) ? true : false;
    } else {
      return true;
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
