import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import titleMixin from "./mixins/titleMixins";
import { apiAgent } from "./services/apiAgent";
import "./directives"; // Custom directives
import { VueReCaptcha } from "vue-recaptcha-v3";

// For more options see below
Vue.use(VueReCaptcha, {
  siteKey: "6LcveC0fAAAAAEQK0V9Ri5ooyL3JWwp5KC3DPewj",
  loaderOptions: {
    autoHideBadge: true,
  },
});

// Vuetify
import vuetify from "./plugins/vuetify";
import "./plugins/animate";

// Global eventbus
export const eventBus = new Vue();

// Eventbus for snackbar toasts
export const snackbarBus = new Vue();

// Title mixins
Vue.mixin(titleMixin);

// // if one these are not registered, npm run build will get Conflicting order, mini-css-extract-plugin??
import EditContr from "./views/RentalContract/EditRentalContract";
import File from "./components/File";
Vue.component("EditContr", EditContr);
Vue.component("File", File);

// STYLES
// Main Theme SCSS
import "@/assets/scss/theme.scss";

// Animation library - https://animate.style/
import "animate.css/animate.min.css";

// OWN COMPONENTS
import WarningComponent from "./components/Warning.vue";
Vue.component("WarningComponent", WarningComponent);

// Interceptor for blob error response
apiAgent.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const responseType = error.request?.responseType;

    if (responseType === "blob" || responseType === "arraybuffer") {
      const data = error.response?.data;

      if (data && data instanceof Blob) {
        if (data.type.toLowerCase().includes("json")) {
          try {
            const jsonText = await data.text();
            error.response.data = JSON.parse(jsonText);
          } catch (parseError) {
            console.error("Failed to parse JSON error from Blob", parseError);
          }
        }
      } else if (data instanceof ArrayBuffer) {
        try {
          const text = new TextDecoder("utf-8").decode(data);
          error.response.data = JSON.parse(text);
        } catch (parseError) {
          console.error("Failed to parse JSON error from ArrayBuffer", parseError);
        }
      }
    }

    return Promise.reject(error);
  }
);

// Attach token to headers on requests
apiAgent.interceptors.request.use(
  (config) => {
    let token = store.getters.getToken;

    // Attach token
    if (token) config.headers["Authorization"] = `Bearer ${token}`;

    // Attach also current browser userData
    config.headers["userdata"] = sessionStorage.getItem("userData") || "null";

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
