<template>
  <v-alert
    dense
    :type="type"
    :prominent="large"
    outlined
    :color="color"
    :icon="icon"
    :style="{ 'margin-bottom': marginBottom + 'px !important' }"
  >
    <slot></slot>
  </v-alert>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "warning",
    },
    type: {
      type: String,
      default: "error",
    },
    large: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "mdi-alert",
    },
    marginBottom: {
      type: String,
      default: "16",
    },
  },
};
</script>

<style scoped>
.v-alert >>> .v-alert__content {
  font-size: 14px;
}
</style>
