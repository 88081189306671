import moment from "moment";
import validations from "@/validations.js";

export default {
  getServiceAccounts(invoiceSettings) {
    return invoiceSettings?.ropoCapital?.accounts ? invoiceSettings.ropoCapital.accounts : [];
  },

  getServiceAccountsIdDocumentPath(isRecurrentInvoice) {
    if (isRecurrentInvoice) return "ropoCapital.recurrentCid";
    else return "invoiceData.ropoCapital.cid";
  },

  getTenantRefNumData(
    principalTenant,
    apartment,
    cid,
    selectAccountFirst,
    serviceAccounts = [],
    invoiceSettings = {}
  ) {
    let selectedCid = null;

    const defaultAccount = serviceAccounts.find((el) => el.isDefault);

    // Select correct cid
    // If apartment cid or selected cid on invoice
    if (apartment && apartment.invoiceData?.ropoCapital?.cid) {
      selectedCid = apartment.invoiceData.ropoCapital.cid;
      console.log("apartment cid found");
    } else if (apartment && apartment?.condominium?.invoiceData?.ropoCapital?.cid) {
      selectedCid = apartment.condominium.invoiceData.ropoCapital.cid;
      console.log("condominium cid found");
    } else if (cid) {
      selectedCid = cid;
      console.log("selected cid found");
    } else if (defaultAccount) {
      selectedCid = defaultAccount.cid;
      console.log("accounts cid found");
    } else {
      selectedCid = invoiceSettings.ropoCapital?.cid || null;
      console.log("default cid found");
    }

    const tenantRefNumList =
      principalTenant?.tenantId?.invoiceData?.ropoCapital?.personalRefNums || [];
    const selectedTenantAccount = tenantRefNumList.find((el) => el.cid === selectedCid);

    // Check that selected account is found from service accountlist
    if (selectedTenantAccount) {
      console.log("tenant account found");
      const found = serviceAccounts.find((el) => el.cid === selectedTenantAccount.cid);
      if (!found) return { referenceNumber: "VIRHE VIITENUMEROSSA", accountServiceId: selectedCid };
      return {
        referenceNumber: selectedTenantAccount.referenceNumber,
        accountServiceId: selectedCid,
      };
    }

    if (!selectAccountFirst) {
      if (
        principalTenant?.tenantId?.personalRefNum &&
        principalTenant.tenantId.personalRefNum.includes(selectedCid)
      ) {
        return {
          referenceNumber: principalTenant?.tenantId?.personalRefNum || null,
          accountServiceId: selectedCid,
        };
      }

      return { referenceNumber: null, accountServiceId: selectedCid };
    } else {
      return { referenceNumber: null, accountServiceId: selectedCid };
    }
  },

  getServiceAccountByServiceId(serviceAccounts, serviceId) {
    return serviceAccounts.find((el) => el.cid === serviceId);
  },

  hasServiceId(invoice) {
    return invoice?.ropoCapital?.jobId ? true : false;
  },

  hasRefundServiceId(invoice) {
    return invoice?.ropoCapital?.refundToJobId ? true : false;
  },

  getRefundServiceId(invoice) {
    return invoice?.ropoCapital?.refundToJobId || null;
  },

  contactHasServiceId() {
    return false;
  },

  canDeleteInvoice(invoice) {
    return !this.hasServiceId(invoice);
  },

  getInvoiceDownloadUrl(invoice, preview, statusCode, groupMode) {
    if (groupMode) {
      return preview
        ? `/api/v1/ropo24/preview-invoice/${invoice._id}?accountId=${invoice.createdBy._id}`
        : `/api/v1/invoices/${invoice._id}/download?accountId=${invoice.createdBy._id}&statusCode=${statusCode}`;
    } else {
      return preview
        ? `/api/v1/ropo24/preview-invoice/${invoice._id}?accountId=${invoice.createdBy}`
        : `/api/v1/invoices/${invoice._id}/download?accountId=${invoice.createdBy}&statusCode=${statusCode}`;
    }
  },

  canDownloadPreviewInvoice() {
    return true;
  },

  getInvoiceState(invoice) {
    if (invoice.invoiceType === "refund") return "Hyvitys";
    if (invoice.paid || invoice.openAmount === 0) return "Maksettu";

    const invoiceReminders = invoice.autoReminder ? true : false;

    let reminder = false;
    let deptCollection = false;
    let reminderNote = false;

    if (invoice.comments) {
      invoice.comments.forEach((el) => {
        if (el.statusCode === 200) reminderNote = true;
        if (el.statusCode === 1) reminder = true;
        if (
          el.statusCode === 2 ||
          el.title.toLowerCase().includes("perintä") ||
          el.text.toLowerCase().includes("perintä")
        ) {
          deptCollection = true;
        }
      });
    }

    if (!invoiceReminders && reminderNote) {
      return "Muistutuskysely";
    }
    if (!reminder && !deptCollection) {
      return "Laskutus";
    } else if (reminder && !deptCollection) {
      return "Muistutus";
    } else if (deptCollection) {
      return "Perintä";
    } else {
      return "";
    }
  },

  invoiceIsDeptCollected(invoice) {
    const state = this.getInvoiceState(invoice);
    if (state == "Muistutus" || state == "Perintä") return false;
    else return true;
  },

  showChangeDueDateBtn() {
    return true;
  },

  canChangeDueDate(invoice) {
    return this.invoiceIsDeptCollected(invoice);
  },

  isDateAvailableForDueDateChange(date, billDate, dueDate) {
    const today = moment().format("YYYY-MM-DD");
    const currentDueDate = moment(moment(dueDate).format("YYYY-MM-DD")).format("YYYY-MM-DD");
    if (moment(date).isSameOrBefore(today) || moment(date).isSame(currentDueDate)) return false;
    else return true;
  },

  getDueDateWarningText() {
    return `Lasku on edennyt muistutus- tai perintätilaan. Et voi enää muuttaa eräpäivää Vuokranetin
            kautta. Tarvittaessa ota yhteys Ropon palveluneuvontaan 030 6886 560 tai
            info@ropocapital.fi`;
  },

  getServiceInvoiceLanguages(globalLanguages) {
    const invoiceLanguages = ["fin", "eng", "swe"];
    return globalLanguages.filter((el) => invoiceLanguages.includes(el.val));
  },

  canSetInvoiceLanguage(principalTenant) {
    if (principalTenant?.tenantId?.invoiceLanguage) return true;
    return false;
  },

  canUpdateTenantDataToService() {
    return false;
  },

  isInvoiceAddressCountryAvailable(country, availableCountries) {
    const available = availableCountries.ropoCapital;
    const filtered = available.filter((el) => el !== undefined && el !== null && el !== "");
    return available.includes(country) || `Valitse: ${filtered}`;
  },

  canUpdateInvoiceStatuses(invoice) {
    return this.hasServiceId(invoice) &&
      (!invoice.comments || (invoice.comments && invoice.comments.length === 0))
      ? true
      : false;
  },

  getSingleInvoiceUpdateData(invoice) {
    if (!invoice.invoiceNumber)
      throw new Error("Laskun numero puuttuu. Laskun tietoja ei voida tarkistaa");
    return { invoiceNumber: invoice.invoiceNumber, invoiceId: invoice._id };
  },

  getInvoiceSearchLabelByService(invoiceType) {
    let text;
    switch (invoiceType) {
      case "invoice":
        text = "Laskunro, ropoId, saaja, saatavat, vuokrakohde, kohteen numero...";
        break;
      case "refundInvoice":
        text = "Laskunro, vuokralainen, hyvitys, vuokrakohde, kohteen numero...";
        break;
    }
    return text;
  },

  validateInvoiceProductCount(isRefundForm) {
    return isRefundForm
      ? validations.required.concat(validations.negativeNumber)
      : validations.required.concat(validations.positiveNumber);
  },
};
